.footer{
    margin-top: 2rem;
    padding-bottom: 1rem;
    font-size: 14px;
    color: #E0E4E8;
    .footer-bold{
      font-weight: 700;
    }
  }
  @media (min-width: 1200px) {
    .footer{
      position: fixed;
      bottom: 14px;
      background-color: #000000;
      padding: 5px;
      width: fit-content !important;
    }
  }
  